import ReactMarkdown from 'react-markdown';
import Globals from '../config/Globals';

export default class ApplicationUtils {}

// Application validation
ApplicationUtils.getApplicationItemsStatuses = function (applicationSpecs, foreignItems, applicationProcess) {
  const filledItems = [];
  const optionalItems = [];

  let equivalenceElectiveMatchCount = 0;
  const equivalenciesReqMatches = [];

  // Equivalencies
  if (applicationSpecs.equivalenciesSection && applicationSpecs.equivalenciesSection.options) {
    for (let option of applicationSpecs.equivalenciesSection.options) {
      const match = ApplicationUtils.getOptionMatch(option, applicationProcess.submissionItems, foreignItems);

      if (match) {
        filledItems.push(option.id);
        const matchElectiveCount = option.matchesElectiveCount || 0;
        equivalenceElectiveMatchCount += matchElectiveCount;

        if (option.matches) {
          equivalenciesReqMatches.push({
            equivalencyName: option.name,
            matches: option.matches,
          });
        }
      }
    }
  }

  // Requirements
  if (applicationSpecs.requirementsSection && applicationSpecs.requirementsSection.requirements) {
    for (let option of applicationSpecs.requirementsSection.requirements) {
      const equivalencyMatch = equivalenciesReqMatches.find(({ matches }) => matches.includes(option.id));
      if (equivalencyMatch) {
        optionalItems.push({ ...option, skippedBy: equivalencyMatch.equivalencyName });
      }

      const match = ApplicationUtils.getOptionMatch(option, applicationProcess.submissionItems, foreignItems);
      if (match) {
        filledItems.push(option.id);
        const matchElectiveCount = option.matchesElectiveCount || 0;
        equivalenceElectiveMatchCount += matchElectiveCount;

        if (option.matches) {
          equivalenciesReqMatches.push({
            equivalencyName: option.name,
            matches: option.matches,
          });
        }
      }
    }
  }

  // Custom requirements sections
  if (applicationSpecs.customRequirementsSections) {
    for (let section of applicationSpecs.customRequirementsSections) {
      if (section.requirements) {
        for (let option of section.requirements) {
          // THIS LINE SHOULD BE REVIEWED BECAUSE ITS DIFFERENT ON API
          const equivalencyMatch = equivalenciesReqMatches.find(({ matches }) => matches.includes(option.id));
          if (equivalencyMatch) {
            optionalItems.push({ ...option, skippedBy: equivalencyMatch.equivalencyName });
          }

          const match = ApplicationUtils.getOptionMatch(option, applicationProcess.submissionItems, foreignItems);
          if (match) {
            filledItems.push(option.id);
            const matchElectiveCount = option.matchesElectiveCount || 0;
            equivalenceElectiveMatchCount += matchElectiveCount;

            if (option.matches) {
              equivalenciesReqMatches.push({
                equivalencyName: option.name,
                matches: option.matches,
              });
            }
          }
        }
      }
    }
  }

  const requiredCount = applicationSpecs.electiveRequirementsSection?.requiredCount || 0;
  // Elective requirements
  let pendingRequiredElectiveCount = Math.max(0, requiredCount - equivalenceElectiveMatchCount);
  console.log('pendingRequiredElectiveCount', pendingRequiredElectiveCount);
  if (applicationSpecs.electiveRequirementsSection && applicationSpecs.electiveRequirementsSection.requirements) {
    for (let option of applicationSpecs.electiveRequirementsSection.requirements || []) {
      const equivalencyMatch = equivalenciesReqMatches.find(({ matches }) => matches.includes(option.id));
      if (equivalencyMatch || pendingRequiredElectiveCount == 0) {
        optionalItems.push({ ...option, skippedBy: equivalencyMatch?.equivalencyName });
      }

      const match = ApplicationUtils.getOptionMatch(option, applicationProcess.submissionItems, foreignItems);
      if (match) {
        filledItems.push(option.id);
        const matchElectiveCount = option.matchesElectiveCount || 0;
        equivalenceElectiveMatchCount += matchElectiveCount;
        pendingRequiredElectiveCount--;

        if (option.matches) {
          equivalenciesReqMatches.push({
            equivalencyName: option.name,
            matches: option.matches,
          });
        }
      }
    }
  }

  return { filledItems, optionalItems, pendingRequiredElectiveCount };
};

ApplicationUtils.getOptionMatch = function (option, submissionItems, foreignItems) {
  const higherMatch = option.acceptedProviders?.find((acceptedProvider) =>
    submissionItems?.find((submissionItem) => submissionItem.id == acceptedProvider.id)
  );
  const lowerMatch =
    higherMatch ||
    option.acceptedProviders?.find((acceptedProvider) =>
      foreignItems.find((foreignItem) => foreignItem.sourceID == acceptedProvider.id)
    );

  return lowerMatch;
};
ApplicationUtils.getMatchingItem = function (option, submissionItems, foreignItems) {
  const higherMatch = submissionItems?.find((submissionItem) =>
    option.acceptedProviders?.find((acceptedProvider) => submissionItem.id == acceptedProvider.id)
  );
  const lowerMatch =
    higherMatch ||
    foreignItems.find((foreignItem) =>
      option.acceptedProviders?.find((acceptedProvider) => foreignItem.sourceID == acceptedProvider.id)
    );
  return lowerMatch;
};

ApplicationUtils.validateApplication = function (certificationSpecs, applicationItemsStatuses, applicationType) {
  const applicationSpecs =
    applicationType == Globals.ApplicationTypes.NEW
      ? certificationSpecs.application
      : certificationSpecs.renewal.application;
  const { requirementsSection, customRequirementsSections } = applicationSpecs;

  // Get all required items IDs
  const requirementsIDs = requirementsSection.requirements?.map((option) => option.id) || [];
  const customSections = (
    customRequirementsSections?.map((section) => section.requirements?.map((option) => option.id) || []) || []
  ).flat();

  // Get the filled and skipped items to match with IDs we got on previous lines
  const { filledItems, optionalItems, pendingRequiredElectiveCount } = applicationItemsStatuses;
  const filledAndSkippedItems = [...filledItems, ...(optionalItems?.map((option) => option.id) || [])];

  // Match filled and skipped items IDs with application required items IDs
  const missingRequirements = requirementsIDs.filter((requirementID) => !filledAndSkippedItems.includes(requirementID));
  const missingCustomSectionsItems = customSections.filter(
    (requirementID) => !filledAndSkippedItems.includes(requirementID)
  );

  return {
    missingRequirements,
    missingCustomSectionsItems,
    pendingRequiredElectiveCount,
  };
};

ApplicationUtils.getTileData = function (tile, applicationProcess, certificationProcess, foreignItems) {
  let status = Globals.ApplicationItemsStatus.DEFAULT;
  let isForeignItem = false;
  let currentProvider = null;
  let submittedItem = null;
  let currentApplicationItem = null;

  currentProvider = tile.acceptedProviders.find((provider) =>
    applicationProcess.submissionItems?.find((subItem) => subItem.id == provider.id)
  );

  if (currentProvider) {
    submittedItem = applicationProcess.submissionItems?.find((subItem) =>
      tile.acceptedProviders.find((provider) => subItem.id == provider.id)
    );

    if (submittedItem) {
      currentApplicationItem = certificationProcess.applicationsItems?.find(
        (appItem) => appItem.sourceID == submittedItem.id && appItem.timestamp == submittedItem.version
      );
    }
  }

  if (!currentProvider && foreignItems && foreignItems?.length > 0) {
    currentProvider = tile.acceptedProviders.find((provider) =>
      foreignItems?.find((foreignItem) => foreignItem.sourceID == provider.id)
    );

    if (currentProvider) {
      currentApplicationItem = foreignItems.find((appItem) => appItem.sourceID == currentProvider.id);

      if (currentApplicationItem) {
        isForeignItem = true;
      }
    }
  }

  if (currentApplicationItem?.isHistoric) {
    status = Globals.ApplicationItemsStatus.HISTORIC;
  } else if (currentApplicationItem && !currentApplicationItem?.approvedBy && !currentApplicationItem?.rejectedBy) {
    status = Globals.ApplicationItemsStatus.UPLOADED;
  } else if (currentApplicationItem && currentApplicationItem?.approvedBy) {
    status = Globals.ApplicationItemsStatus.APPROVED;
  } else if (currentApplicationItem && currentApplicationItem?.rejectedBy) {
    status = Globals.ApplicationItemsStatus.REJECTED;
  }

  return { status, currentProvider, submittedItem, currentApplicationItem, isForeignItem };
};

// Application UI
ApplicationUtils.getSections = function (application, metadata) {
  const sections = [];
  console.log('*-*-*- getSections', application, metadata);

  const { equivalenciesSection, requirementsSection, electiveRequirementsSection, customRequirementsSections } =
    application;

  if (equivalenciesSection) {
    const sectionMetadata = metadata?.equivalenciesSection || {};
    sections.push({ ...ApplicationUtils.getSectionObject(equivalenciesSection, 'options'), metadata: sectionMetadata });
  }

  if (requirementsSection) {
    console.log('*-*-*-requirementsSection', requirementsSection);
    const sectionMetadata = metadata?.requirementsSection || {};
    sections.push({
      ...ApplicationUtils.getSectionObject(requirementsSection, 'requirements'),
      metadata: sectionMetadata,
    });
    console.log('*-*-*- pushed requirementsSection', {
      ...ApplicationUtils.getSectionObject(requirementsSection, 'requirements'),
      metadata: sectionMetadata,
    });
  }

  if (electiveRequirementsSection) {
    const sectionMetadata = metadata?.electiveRequirementsSection || {};
    sections.push({
      ...ApplicationUtils.getSectionObject(electiveRequirementsSection, 'requirements'),
      metadata: sectionMetadata,
    });
  }

  if (customRequirementsSections) {
    customRequirementsSections.forEach((section) => {
      sections.push(ApplicationUtils.getSectionObject(section, 'requirements'));
    });
  }

  return sections;
};

ApplicationUtils.getSectionObject = function (section, tilesPropName) {
  let alert = null;

  if (section.alert) {
    alert = {
      title: section.alert.title || 'Attention!',
      description: <ReactMarkdown children={section.alert.description} linkTarget="_blank" />,
      type: section.alert.type || 'error',
    };
  }

  return {
    key: section.name,
    title: section.name,
    description: section.sectionDescription,
    alert,
    tiles: section[tilesPropName].sort((a, b) => a.order - b.order),
  };
};
